<template>
  <v-card>
    <vue-confirm-dialog></vue-confirm-dialog>
    <v-toolbar
        color="primary"
        dark
        max-height="55px"
        short
    >
      <v-btn
          dark
          icon
          @click="close()"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>Reservatie #{{ booking.ID }} - {{ booking.ID ? booking.Name : "Nieuw" }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn v-if="booking.ID"
               color="error"
               class="delete"
               text
               @click="del()"
        >
          Verwijderen
        </v-btn>
        <v-btn
            dark
            text
            @click="save()"
        >
          Bewaren
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-tabs :grow="false" :style="{flexGrow:0}">
      <v-tab @click="tab=1">Boeking</v-tab>
      <v-tab @click="tab=2">Betaling</v-tab>
      <v-tab @click="tab=4">Mollie</v-tab>
      <v-tab v-if="booking.PaymentID" @click="tab=3">Gasten</v-tab>
    </v-tabs>
    <v-list
        v-if="tab==1"
        subheader
        three-line
    >
      <v-subheader>Gegevens reservatie</v-subheader>
      <v-list-item>
        <v-list-item-content>
          <v-row>
            <v-col md="6" sm="12">
              <v-menu
                  ref="menu1"
                  v-model="menu1"
                  :close-on-content-click="false"
                  min-width="auto"
                  offset-y
                  transition="scale-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="date1"
                      v-bind="attrs"
                      v-on="on"
                      append-icon="mdi-calendar"
                      hide-details
                      label="Datum inchecken"
                      outlined
                      readonly
                      v-on:click:append="menu1=true"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="booking.From.toISOString().split('T')[0]"
                    min="1950-01-01"
                    @change="saveFrom"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col md="6" sm="12">
              <v-menu
                  ref="menu2"
                  v-model="menu2"
                  :close-on-content-click="false"
                  min-width="auto"
                  offset-y
                  transition="scale-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="date2"
                      v-bind="attrs"
                      v-on="on"
                      append-icon="mdi-calendar"
                      hide-details
                      label="Datum uitchecken"
                      outlined
                      readonly
                      v-on:click:append="menu2=true"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="booking.Till.toISOString().split('T')[0]"
                    min="1950-01-01"
                    @change="saveTill"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col md="3" sm="6">
              <v-select v-model="booking.Status" :items="statusItems" item-text="name" item-value="id"
                        label="Status reservering" outlined></v-select>
            </v-col>
            <v-col md="3" sm="6">
              <v-text-field v-model="booking.BookingDetail.Adults"
                            hide-details
                            label="Aantal gasten"
                            outlined
                            type="number"
                            @focus="$event.target.select()"
              />
            </v-col>
            <v-col md="3" sm="6">
              <v-text-field v-model="booking.BookingDetail.Children"
                            hide-details
                            hide-details
                            label="Aantal kinderen"
                            outlined
                            type="number"
                            @focus="$event.target.select()"
              />
            </v-col>
            <v-col md="3" sm="6">
              <v-text-field v-model="booking.BookingDetail.Babies"
                            hide-details
                            hide-details
                            label="Aantal babies"
                            outlined
                            type="number"
                            @focus="$event.target.select()"
              />
            </v-col>
          </v-row>
        </v-list-item-content>
      </v-list-item>
      <v-subheader>Gegevens accomodatie</v-subheader>
      <v-list-item>
        <v-list-item-content>
          <v-row>
            <v-col md="3" sm="6">
              <v-select v-model="booking.IdAccomodation" :items="accomodations" item-text="Name" item-value="ID"
                        label="Accomodatie" outlined></v-select>
            </v-col>
            <v-col md="3" sm="6">
              <v-select v-model="booking.IdRoom" :items="roomList" item-text="Name" item-value="ID" label="Perceel"
                        outlined></v-select>
            </v-col>
          </v-row>
        </v-list-item-content>
      </v-list-item>
      <v-subheader>Gegevens gast</v-subheader>
      <v-list-item>
        <v-list-item-content>
          <v-row>
            <v-col md="3" sm="6">
              <v-text-field v-model="booking.BookingDetail.Firstname" hide-details label="Voornaam" outlined/>
            </v-col>
            <v-col md="3" sm="6">
              <v-text-field v-model="booking.BookingDetail.Lastname" hide-details label="Achternaam" outlined/>
            </v-col>
            <v-col md="3" sm="6">
              <v-text-field v-model="booking.BookingDetail.Email" hide-details label="Email" outlined/>
            </v-col>
            <v-col md="3" sm="6">
              <v-text-field v-model="booking.BookingDetail.Phone" hide-details label="Telefoon" outlined/>
            </v-col>
            <v-col md="12">
              <v-textarea v-model="booking.BookingDetail.Info" hide-details label="Opmerkingen" outlined></v-textarea>
            </v-col>

          </v-row>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-list v-if="tab==2" subheader>
      <v-subheader>Info betaling</v-subheader>
      <v-list-item>
        <over-view :value="booking" :accomodation="accomodation" />
      </v-list-item>
    </v-list>
    <v-list v-if="tab==3" subheader>
      <v-list-item>
        <guest-details :value="booking"/>
      </v-list-item>
    </v-list>
    <div v-if="tab==4" >
        <payment-overview @update="updatePayment" :payment="paymentData" />
    </div>
  </v-card>

</template>

<script>
import {Accomodation} from "@/models/accomodation";
import {Room} from "@/models/room";
import {BookingDetail} from "@/models/booking-detail";
import {Booking} from "@/models/booking";
import OverView from "@/components/booking/OverView.vue"
import GuestDetails from "@/components/guests/GuestDetails.vue"
import PaymentOverview from "@/components/booking/Payment.vue"

const {Payment} = require("@/models/payment");
const {PaymentService} = require("@/services/payment-service");
const {RoomService} = require("@/services/room-service");
const {AccomodationService} = require("@/services/accomodation-service");
const {BookingService} = require("@/services/booking-service");

export default {
  data() {
    return {
      tab: 1,
      menu1: false,
      menu2: false,
      accomodations: Array[Accomodation] = [],
      rooms: Array[Room] = [],
      detail: BookingDetail,
      accomodation: Accomodation,
      paymentData: Payment,
    }
  },
  props: {
    booking: [Booking]
  },
  watch:{
    async "booking.IdAccomodation"(){
      this.accomodation = await AccomodationService.single(this.booking.IdAccomodation);
      this.booking.Options = this.accomodation.Options;
    }
  },
  computed: {
    rent() {
      let rent = 0;
      for (var d = new Date(this.booking.From); d < new Date(this.booking.Till); d.setDate(d.getDate() + 1)) {
        rent += this.getDayPrice(d);
      }

      return rent
    },
    date1() {
      let t = this.booking.From;
      return t.toLocaleDateString("nl-NL", {
        weekday: 'short',
        //timeZone: 'UTC'
      }) + ", " + t.getDate() + " " + t.toLocaleString("nl-NL", {
        month: 'short',
        //timeZone: 'UTC'
      }) + " " + t.getFullYear();
    },
    date2() {
      let t = this.booking.Till;
      return t.toLocaleDateString("nl-NL", {
        weekday: 'short',
        //timeZone: 'UTC'
      }) + ", " + t.getDate() + " " + t.toLocaleString("nl-NL", {
        month: 'short',
        //timeZone: 'UTC'
      }) + " " + t.getFullYear();
    },
    statusItems() {
      return this.booking.getBookingStatus();
    },
    roomList() {
      return this.rooms.filter(r => r.IdAccomodation === this.booking.IdAccomodation)
    }
  },
  methods: {
    getDayPrice(date) {

      if (!this.booking)
        return 0;

      let p = 0;


      this.accomodation.Pricing.forEach(function (item) {
        if ((date.getTime() <= item.Till.getTime() && date.getTime() >= item.From.getTime())) {
          p = item.Price;
        }
      });

      return parseFloat(p);
    },
    close() {
      this.$emit('close');
    },
    async save() {
      if (this.booking.ID) {
        await BookingService.update(this.booking);
      } else {
        await BookingService.createBooking(this.booking);
      }
      this.$emit("update")
    },
    del() {
      this.$confirm(
          {
            message: 'Wil je deze boeking verwijderen',
            button: {
              no: 'Neen',
              yes: 'Ja'
            },
            callback: async confirm => {
              if (confirm) {
                await BookingService.deleteBooking(this.booking);
                this.$emit("update")
                this.$emit("close")
              }
            }
          }
      )

    },
    saveFrom($event) {
      this.booking.From = new Date($event);
      this.menu1 = false;
    },
    saveTill($event) {
      this.booking.Till = new Date($event);
      this.menu2 = false;
    },
    async updatePayment(){
      if(this.booking.PaymentID){
        this.paymentData = await PaymentService.get(this.booking.PaymentID)
      }
    }
  },
  async mounted(){
    this.accomodations = await AccomodationService.get();
    this.rooms = await RoomService.get();
    if(this.booking.PaymentID){
      this.paymentData = await PaymentService.get(this.booking.PaymentID)
    }
  },
  async created() {
    this.accomodation = await AccomodationService.single(this.booking.IdAccomodation);
    if(!this.booking.ID){
      this.booking.Options = this.accomodation.Options;
    }

  },
  components: {
    OverView,
    GuestDetails,
    PaymentOverview
  }
}
</script>
<style scoped>
.delete{ background: #ba3c3c; color:#fff!important; }
</style>