<template>
  <div class="c-row top">
    <div class="c-row-title"></div>
    <calendar-cel v-for="(item,i) in dateRange" :class="{'today':isToday(item[2])}"  :key="i"  >
      <span class="c-title">{{ item[0] }}</span>
      <span>{{ item[1] }}</span>
    </calendar-cel>
  </div>
</template>

<style scoped>
  .c-row.top > .c-row-title{  z-index: 2; }
  .c-row{
    position: absolute; width: 100%;
    border-top:1px solid #e0e0e0; border-bottom:1px solid rgba(0, 0, 0, 0.12);
    display: flex;
    flex-direction: row;
    align-items: stretch;
    z-index: 2;
    overflow-y:scroll;
  }
  .c-row-title{ background:#fff; width: 240px; border-right: 1px solid rgba(0, 0, 0, 0.12); padding: 10px 15px; }

  >>> .c-cel{ background: #ededed; display: flex; flex-direction: column; text-align: center; }
  >>> .c-cel > .c-title{ font-weight: bold; }
  >>> .c-cel:last-child{ border-right: 0; }
  >>> .c-cel.today{ background: #e7e3fa; }

</style>
<script>
import CalendarCel from "@/components/calendar/CalendarCel";
export default {
  components: {CalendarCel},
  props:{
    from: Date
  },
  methods:{
    isToday(date){
      //return false;
      let t = new Date();
      return date.setHours(0,0,0,0) == t.setHours(0,0,0,0);
    }
  },
  computed:{
    dateRange(){
      let d = new Date(this.from.getTime());
      let r = [];
      for (let i = 1; i < 11; i++) {

        d.setDate(d.getDate() + (i===1?0:1));
        r.push([d.toLocaleDateString("nl-NL", { weekday: 'short'}),d.getDate(),new Date(d)])
      }
      return r;
    }
  }
}
</script>