import {BookingDetail} from "@/models/booking-detail";
import {AccomodationOption} from "@/models/accomodation-option";
import {MainGuest} from "./main-guest";
import {AdditionalGuest} from "./additional-guest";

export class Booking {
	ID: number = 0;
	IdAccomodation: number = 0;
	IdRoom: number = 0;
	Status: number = 1;
	Name: string = "";
	PaymentID: string = "";
	From: Date = new Date();
	Till: Date = new Date();
	BookingDetail: Object = new BookingDetail()
	Options: AccomodationOption[] = [];
	CancellationInsurance: boolean = false;
	DepositCost: number = 0;
	MainGuest: MainGuest = new MainGuest();
	AdditionalGuests: AdditionalGuest[] = [];
	AdditionalChildren: AdditionalGuest[] = [];

	public constructor(data?: any) {
		if (!data) return;
		this.ID = data.id;
		this.Status = data.status;
		this.Name = data.name || "";
		this.PaymentID = data.id_payment || "";
		this.IdAccomodation = data.id_accomodation || 0;
		this.IdRoom = data.id_room || 0;
		this.CancellationInsurance = parseInt(data.cancellation_insurance)!==0 || false
		this.From = new Date(data.from) || new Date();
		this.Till = new Date(data.till) || new Date();
		this.BookingDetail = new BookingDetail(data)
		this.DepositCost = data.DepositCost || 0
		if(data.MainGuest){
			this.MainGuest = new MainGuest(data.MainGuest);
		}
		if(data.AdditionalGuests){
			this.AdditionalGuests = data.AdditionalGuests.map((u:AdditionalGuest) => new AdditionalGuest(u))
		}

		if(data.AdditionalChildren){
			this.AdditionalChildren = data.AdditionalChildren.map((u:AdditionalGuest) => new AdditionalGuest(u))
		}

		if (data.Options) {
			this.Options = data.Options.map((o: AccomodationOption) => new AccomodationOption(o))
		}
	}

	getBookingStatus() {

		return [
			{
				id: "1",
				name: "Gereserveerd",
			},
			{
				id: "2",
				name: "Ingecheckt",
			},
			{
				id: "3",
				name: "Uitgecheckt",
			}, {
				id: "4",
				name: "Niet beschikbaar",
			}, {
				id: "5",
				name: "Geannuleerd",
			}
		];

	}

	totalDays() {

		if (!this.Till || !this.From)
			return 0;

		let t = this.Till.getTime() - this.From.getTime();
		return Math.ceil(t / (1000 * 3600 * 24));

	}


}