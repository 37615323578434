<template>
  <v-container fluid>
    <Calendar/>
  </v-container>
</template>
<script>
import Calendar from "@/components/calendar/CalendarWrapper";
export default {
  components: {Calendar}
}
</script>