import {Axios} from "@/utils/axios";
import {Payment} from "@/models/payment";

export class PaymentServiceClass {

	public url: string = "https://booking-api.webcode.be/api/payment"

	async get(id:string) {
		try {
			let response = await Axios.get(this.url + "/info?id="+id);
			if (response.status == 200) {
				return new Payment(response.data.data);
			}
		} catch (e) {

		}
	}

	async refund(id:string,amount:number) {
		try {
			let response = await Axios.post(this.url + "/refund",{id,amount});
			if (response.status == 200) {
				return new Payment(response.data.data);
			}
		} catch (e) {

		}
	}

}

export const PaymentService = new PaymentServiceClass();