<template>
  <div>
    <v-row>
      <v-col sm="12" >
      <b>{{ info }}</b>
      </v-col>
    </v-row>
    <v-row class="mt-2">
      <v-col sm="6" >
        <v-text-field hide-details outlined :class="{error:error && val.FirstName.length==0}" label="Voornaam" v-model="val.FirstName"></v-text-field>
      </v-col>
      <v-col sm="6">
        <v-text-field hide-details outlined :class="{error:error && val.LastName.length==0}" label="Achternaam" v-model="val.LastName"></v-text-field>
      </v-col>
    </v-row>
    <v-row class="mt-3">
      <v-col sm="6">
        <v-text-field hide-details outlined label="Geboortedatum" :class="{error:error && val.DateOfBirth.length==0}" v-model="val.DateOfBirth"></v-text-field>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import {AdditionalGuest} from "@/models/additional-guest";

  export default {
    data() {
      return {
        val: this.value
      }
    },
    watch: {
      val() {
        this.$emit("input", this.val);
      }
    },
    props: {
      info: String,
      value: [AdditionalGuest],
      error: Boolean
    }
  }
</script>