<template>
  <v-row v-if="accomodation.ID" :style="{padding:'15px'}">
    <v-col md="6" sm="12">
      <v-row>
        <v-col v-if="value.Till" class="mt-2 mb-2" sm="12"><b>{{ totalGuests }}
          {{ totalGuests > 1 ? 'personen' : 'persoon' }} /
          {{ value.totalDays() }}
          {{ value.totalDays() > 1 ? 'nachten' : 'nacht' }}</b></v-col>
      </v-row>
      <div v-if="value.Till">
        <v-row class="v-row">
          <v-col class=" mt-2" sm="12"><b>Reservatiekost:</b></v-col>
          <v-col class=" mt-1" sm="8">Huurprijs</v-col>
          <v-col class=" mt-1 text-end" sm="4">&euro; {{ rent.toFixed(2) }}</v-col>
          <v-col v-if="rentAdditionGuests > 0" class=" mt-1" sm="8">Extra Gasten</v-col>
          <v-col v-if="rentAdditionGuests > 0" class=" mt-1 text-end" sm="4">&euro; {{
              rentAdditionGuests.toFixed(2)
            }}
          </v-col>
        </v-row>
        <v-row v-if="discountAmount > 0">

            <v-col class=" mt-2" sm="12"><b>Extra korting voor lang verblijf</b></v-col>
            <v-col class=" mt-1" sm="8">Reeds afgehouden korting</v-col>
            <v-col class=" mt-1 text-end" sm="4">&euro; {{ discountAmount.toFixed(2) }}</v-col>

        </v-row>
        <v-row v-if="value.CancellationInsurance || calculatedOptions.length>0" class="v-row mt-2">
          <v-col sm="12"><b>Extra opties</b></v-col>
          <v-col v-if="value.CancellationInsurance" class=" mt-1" sm="8">Annulatieverzekering:</v-col>
          <v-col v-if="value.CancellationInsurance" class=" mt-1 text-end" sm="4">&euro;
            {{ cancellationInsuranceCost.toFixed(2) }}
          </v-col>
        </v-row>
        <v-row v-for="option in calculatedOptions" :key="option.ID">
          <v-col class=" mt-1" sm="8">{{ option.Name }}:</v-col>
          <v-col class=" mt-1 text-end" sm="4">&euro;
            {{ (option.Price * option.Qty * (option.Multiply ? value.totalDays() : 1)).toFixed(2) }}
          </v-col>
        </v-row>

        <v-row class="v-row mt-2">
          <v-col class=" mt-2" sm="12"><b>Extra</b></v-col>
        </v-row>
        <v-row v-for="cost in accomodation.Costs" :key="cost.ID">
          <v-col class=" mt-1" sm="8">{{ cost.Name }}</v-col>
          <v-col class=" mt-1 text-end" sm="4">&euro; {{ (cost.Price).toFixed(2) }}
          </v-col>
        </v-row>
        <v-row class="total mt-2">
          <v-col class=" mt-2" sm="8"><b>TOTAAL</b></v-col>
          <v-col class=" mt-2 text-end" sm="4">&euro; {{ totalToCharge.toFixed(2) }}</v-col>
        </v-row>
      </div>
    </v-col>
    <v-col md="5" offset-md="1" sm="12">
      <v-row>
        <v-col sm="12">

        </v-col>
        <v-col sm="12">
          <v-switch
              v-model="value.CancellationInsurance"
              color="success"
              :label="`Annulatieverzekering`"></v-switch>
          <v-list-group-item class="d-flex flex-row align-items-center" :key="option.ID" v-for="(option,index) in value.Options">
            <div class="d-flex flex-row align-items-center mt-5"  v-if="option.Available>1">
              <v-text-field
                  v-model="value.Options[index].Qty"
                  outlined
                  type="number"
                  :label="option.Name"

              />
            </div>
            <div class="form-check form-switch form-select-lg" v-if="option.Available==1">
              <v-switch
                  v-model="value.Options[index].Qty"
                  :label="option.Name"
                  color="success"
              ></v-switch>
            </div>
          </v-list-group-item>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import {Booking} from "@/models/booking";
import {Accomodation} from "@/models/accomodation";
import {DiscountService} from "@/services/discount-service";
import {Discount} from "@/models/discount";


export default {
  data() {
    return {
      rentCost: 0,
      additionGuestsCost: 0,
      showDiscount: false,
      DiscountCode: "",
      inValidCode: false,
      appliedDiscount: null | Discount,
      accept: false
    }
  },
  computed: {
    rentFull(){
      let rent = 0;
      for (var d = new Date(this.value.From); d < new Date(this.value.Till); d.setDate(d.getDate() + 1)) {
        rent += this.getDayPrice(d);
      }
      return rent;
    },
    rentAdditionGuestsFull(){
      let rent = 0;
      if (this.accomodation.FreeGuestsIncluded == 0) {
        rent = ((this.accomodation.ExtraGuestCost * this.value.totalDays()) * (this.value.Guests));
        rent += ((this.accomodation.ExtraChildCost * this.value.totalDays()) * (this.value.Children));
      } else {
        if (this.value.Guests > this.accomodation.FreeGuestsIncluded) {
          console.log("1")
          rent += ((this.accomodation.ExtraGuestCost * this.value.totalDays()) * (this.value.Guests - this.accomodation.FreeGuestsIncluded));
        }
        if (this.accomodation.FreeGuestsIncluded > 0 && this.value.Guests >= this.accomodation.FreeGuestsIncluded && this.value.Children > 0) {
          console.log("2")
          rent += ((this.accomodation.ExtraChildCost * this.value.totalDays()) * this.value.Children);
        }
        if (this.accomodation.FreeGuestsIncluded > 0 && this.value.Guests === 1 && this.value.Children > 1) {
          console.log("3")
          rent += ((this.accomodation.ExtraChildCost * this.value.totalDays()) * (this.value.Children - 1));
        }
      }
      return rent;
    },
    discountAmount(){
      let discount = 0;
      discount += this.rentFull - this.rent;
      discount += this.rentAdditionGuestsFull - this.rentAdditionGuests;
      return discount
    },
    rent() {
      let rent = 0;
      for (var d = new Date(this.value.From); d < new Date(this.value.Till); d.setDate(d.getDate() + 1)) {
        rent += this.getDayPrice(d);
      }


      if(this.value.totalDays()>=15){
        rent = rent * 0.85;
        return rent;
      }

      if(this.value.totalDays()>=7){
        rent = rent * 0.95;
        return rent;
      }

      return rent
    },
    rentAdditionGuests() {
      let rent = 0

      //2 guests included

      if (this.accomodation.FreeGuestsIncluded == 0) {
        rent = ((this.accomodation.ExtraGuestCost * this.value.totalDays()) * (this.value.BookingDetail.Adults));
        rent += ((this.accomodation.ExtraChildCost * this.value.totalDays()) * (this.value.BookingDetail.Children));
      } else {
        if (this.value.BookingDetail.Adults > this.accomodation.FreeGuestsIncluded) {
          console.log("here1");
          rent += ((this.accomodation.ExtraGuestCost * this.value.totalDays()) * (this.value.BookingDetail.Adults - this.accomodation.FreeGuestsIncluded));
        }
        if (this.accomodation.FreeGuestsIncluded > 0 && this.value.BookingDetail.Adults > this.accomodation.FreeGuestsIncluded && this.value.BookingDetail.Children > 0) {
          console.log("here2");
          rent += ((this.accomodation.ExtraChildCost * this.value.totalDays()) * this.value.BookingDetail.Children);
        }
        if (this.accomodation.FreeGuestsIncluded > 0 && this.value.BookingDetail.Adults == 1 && this.value.BookingDetail.Children > 1) {
          console.log("here3");
          rent += ((this.accomodation.ExtraChildCost * this.value.totalDays()) * (this.value.BookingDetail.Children - 1));
        }
        if ((this.accomodation.FreeGuestsIncluded > 0 && this.value.BookingDetail.Adults == this.accomodation.FreeGuestsIncluded) && this.value.BookingDetail.Children > 0) {
          console.log("here4");
          rent += ((this.accomodation.ExtraChildCost * this.value.totalDays()) * (this.value.BookingDetail.Children));
        }
      }

      if(this.value.totalDays()>=15){
        rent = rent * 0.85;
        return rent;
      }

      if(this.value.totalDays()>=7){
        rent = rent * 0.95;
        return rent;
      }

      return rent;
      //return rent === 0 ? 0 : (rent - this.rent)

    },
    cancellationInsuranceCost() {

      if (!this.value.CancellationInsurance)
        return 0;

      return (this.total / 100 * 8.5)
    },
    total() {
      let t = 0;
      t += (parseFloat(this.rent + this.rentAdditionGuests) + this.value.DepositCost);
      let _ = this;

      console.log(this.rent, this.rentAdditionGuests, this.DepositCost);

      this.value.Options.forEach(function (item) {
        if (item.Qty > 0) {
          let p = (item.Qty * item.Price);
          console.log(item.Multiply);
          if (item.Multiply) {
            t += p * _.value.totalDays();
          } else {
            t += p;
          }

        }
      });

      return parseFloat(t);
    },
    accomodationExtraCosts() {

      let t = 0;
      let _ = this
      this.accomodation.Costs.forEach(function (item) {
        let p = item.Price;
        if (item.Multiply) {
          t += p * _.value.totalDays();
        } else {
          t += p;
        }
      });

      return t;

    },
    totalBeforeDiscount() {
      let t = this.total

      t += this.accomodationExtraCosts;
      t += this.cancellationInsuranceCost;

      return parseFloat(t);
    },
    totalToCharge() {
      let a = parseFloat(this.total);

      if (this.appliedDiscount) {
        if (this.appliedDiscount.Type == 1) {
          a = a - this.appliedDiscount.Discount
        }
        if (this.appliedDiscount.Type == 2) {
          a = a * ((100 - this.appliedDiscount.Discount) / 100);
        }
      }

      a += this.accomodationExtraCosts;
      a += this.cancellationInsuranceCost


      return a;
    },
    calculatedOptions() {
      return this.value.Options.filter((o) => o.Qty > 0);
    },
    totalGuests() {
      return parseInt(this.value.BookingDetail.Adults) + parseInt(this.value.BookingDetail.Children) + parseInt(this.value.BookingDetail.Babies);
    }
  },
  props: {
    value: [Booking],
    accomodation: [Accomodation],
  },
  methods: {
    niceDate(date) {

      if (!date) {
        return "-"
      }

      return date.toLocaleDateString("nl-NL", {
        weekday: 'short',
        //timeZone: 'UTC'
      }) + ", " + date.getDate() + " " + date.toLocaleString("nl-NL", {
        month: 'short',
        //timeZone: 'UTC'
      }) + " " + date.getFullYear();
    },
    getDayPrice(date) {

      if (!this.value)
        return 0;

      let p = 0;

      this.accomodation.Pricing.forEach(function (item) {
        let from = new Date(item.From);
        let till = new Date(item.Till);
        if ((date <= till.setHours(0,0,0,0) && date >= from.setHours(0,0,0,0)) || date == from.setHours(0,0,0,0)) {
          p = item.Price;
        }
      });

      return parseFloat(p);
    },
    async validateDiscount() {
      let c = await DiscountService.validate(this.DiscountCode);
      this.$refs['modal-1'].hide()
      if (!c) {
        this.$refs['modal-1'].show()
      } else {
        this.appliedDiscount = c;
      }
    },
    next() {
      this.$refs['modal-2'].hide()
      if (!this.accept) {
        this.$refs['modal-2'].show()
        return
      }
      this.$emit('next');
    },
  },
  mounted() {

  }

}
</script>
<style scoped>
  .col { padding: 5px; }
</style>
<style>
.modal-header button { background: none; border: none; font-size: 19px; }
hr { height: 20px; border-bottom: 1px solid #000; margin-top: 10px; }
.options { border-top: 1px solid #ccc; }
.v-row { border-top: 1px solid #ccc; }
.total { border-top: 1px solid #ccc; font-size: 18px; }
.room-name { opacity: 0.8; }
</style>