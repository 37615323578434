export class Room {
	ID: number = 0;
	IdAccomodation: number = 0;
	Name: string = "";
	From: Date = new Date();
	Till: Date = new Date();


	public constructor(data?:any) {
		if (!data) return;
		this.ID = data.id;
		this.Name = data.name;
		this.IdAccomodation = data.id_accomodation;
		this.From = data.from;
		this.Till = data.till;
	}

}