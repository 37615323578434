import {Axios} from "@/utils/axios";
import {Booking} from "@/models/booking";


export class BookingServiceClass {

	public url: string = "https://booking-api.webcode.be/api"

	async get(from:Date,till:Date) {
		try {
			let response = await Axios.get(this.url + "/bookings?from="+from+"&till="+till);
			if (response.status == 200) {
				return response.data.data.map((a:any) => new Booking(a));
			}
		} catch (e) {

		}
	}

	async single(id:number) {
		try {
			let response = await Axios.get(this.url + "/booking/"+id);
			if (response.status == 200) {
				return new Booking(response.data.data)
			}
		} catch (e) {

		}
	}

	async deleteBooking(booking:Booking){
		try {
			let response = await Axios.delete(this.url + "/booking/"+booking.ID);
			if (response.status == 200) {
				//return new Booking(response.data.data)
			}
		} catch (e) {

		}
	}

	async createBooking(booking:Booking){
		try {
			let response = await Axios.post(this.url + "/booking",{booking});
			if (response.status == 200) {
				//return new Booking(response.data.data)
			}
		} catch (e) {

		}
	}

	async update(booking:Booking){
		try {
			let response = await Axios.put(this.url + "/booking",{booking});
			if (response.status == 200) {
				//return new Booking(response.data.data)
			}
		} catch (e) {

		}
	}

}

export const BookingService = new BookingServiceClass();