export class Payment {
	ID: number = 0;
	Mode: string = "";
	Amount: number = 0;
	AmountRefunded: number = 0;
	AmountRemaining: number = 0;
	AmountChargedBack: number = 0;
	PaidAt: Date = new Date();

	public constructor(data?:any) {
		console.log("data",data);
		this.ID = data.id;
		this.Mode = data.mode;
		this.PaidAt = new Date(data.paidAt);
		this.Amount = data.amount.value;
		this.AmountRefunded = data.amountRefunded.value;
		this.AmountRemaining = data.amountRemaining.value;
		if(data.amountChargedBack){
		   this.AmountChargedBack = data.amountChargedBack.value;
		}
	}

}