<template>
  <v-list v-if="payment.ID"
          subheader
          three-line
  >
    <v-subheader>Gegevens betaling via Mollie</v-subheader>

    <v-container fluid>
      <v-row no-gutters>
        <v-col md="6" sm="12">
          <v-text-field v-model="payment.ID"
                        disabled
                        label="Betalings ID" outlined/>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col md="6" sm="12">
          <v-text-field v-model="payment.Amount"
                        disabled
                        label="Betalingsbedrag" outlined

          />
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col md="6" sm="12">
          <v-text-field v-model="payment.AmountRemaining"
                        disabled
                        label="Overgebleven bedrag ( - terugstortingen)" outlined

          />
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col md="6" sm="12">
          <v-text-field v-model="payment.AmountRefunded"
                        disabled
                        label="Bedrag reeds terugbetaald" outlined

          />
        </v-col>
      </v-row>
      <v-divider/>
      <v-row class="mt-6" no-gutters>
        <v-col md="6" sm="12">
          <v-text-field v-model="toBeRefunded"
                        label="Terug te vorderen bedrag"
                        outlined
                        type="number"
                        @focus="$event.target.select()"

          />
        </v-col>
      </v-row>
      <v-btn color="primary" elevation="2"
             @click="reFund()">Terugbetaling aanvragen
      </v-btn>
    </v-container>

  </v-list>
</template>

<script>

import {Payment} from "@/models/payment";
const {PaymentService} = require("@/services/payment-service");

export default {
  data() {
    return {
      toBeRefunded: 0
    }
  },
  computed: {},
  props: {
    payment: [Payment],
  },
  methods: {
    reFund() {
      let t = this.$confirm(
          {
            message: 'Wil je EUR ' + this.toBeRefunded + ' terugstorten van deze boeking?',
            button: {
              no: 'Neen',
              yes: 'Ja'
            },
            callback: async confirm => {
              if (confirm) {
                await PaymentService.refund(this.payment.ID, this.toBeRefunded);
                this.$emit("update");
                t.close()
                return;
              }
            }
          }
      )
    }
  },
  mounted() {

  }

}
</script>
<style scoped>
  .col { padding: 5px; }
</style>
<style>

</style>