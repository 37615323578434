<template>
  <div>
    <v-row>
      <v-col sm="12" >
        <b>Hoofdgast</b>
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="6">
        <v-text-field hide-details outlined :class="{error:error && val.FirstName.length==0}" v-model="val.FirstName" label="Voornaam"></v-text-field>
      </v-col>
      <v-col sm="6">
        <v-text-field hide-details outlined :class="{error:error && val.LastName.length==0}" v-model="val.LastName"  label="Achternaam"></v-text-field>
      </v-col>
    </v-row>
    <v-row class="mt-3">
      <v-col sm="6">
        <v-text-field hide-details outlined :class="{error:error && val.Street.length==0}" v-model="val.Street" label="Straatnaam"></v-text-field>
      </v-col>
      <v-col sm="6">
        <v-text-field hide-details outlined :class="{error:error && val.Number.length==0}" v-model="val.Number" label="Huisnummer"></v-text-field>
      </v-col>
    </v-row>
    <v-row class="mt-3">
      <v-col sm="6">
        <v-text-field hide-details outlined :class="{error:error && val.Postal.length==0}" v-model="val.Postal" label="Postcode"></v-text-field>
      </v-col>
      <v-col sm="6">
        <v-text-field hide-details outlined :class="{error:error && val.City.length==0}" v-model="val.City" label="Plaats / gemeente"></v-text-field>
      </v-col>
    </v-row>
    <v-row class="mt-3">
      <v-col sm="6">
        <v-text-field hide-details outlined :class="{error:error && val.Email.length==0}" v-model="val.Email" label="E-mailadres"></v-text-field>
      </v-col>
      <v-col sm="6">
        <v-text-field hide-details outlined :class="{error:error && val.Phone.length==0}" v-model="val.Phone" label="GSM-nummer"></v-text-field>
      </v-col>
    </v-row>
    <v-row class="mt-3">
      <v-col sm="6">
        <v-text-field hide-details outlined :class="{error:error && val.DateOfBirth.length==0}" v-model="val.DateOfBirth" label="Geboortedatum"></v-text-field>
      </v-col>
      <v-col sm="6">
        <v-text-field hide-details outlined :class="{error:error && val.Country.length==0}" v-model="val.Country" label="Land"></v-text-field>
      </v-col>
    </v-row>
    <v-row class="mt-3">
      <v-col sm="12">
        <v-text-field hide-details outlined v-model="val.Info" label="Opmerkingen"></v-text-field>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import {MainGuest} from "@/models/main-guest";

  export default {
    data() {
      return {
        val: this.value
      }
    },
    props: {
      info: String,
      value: [MainGuest],
      error: Boolean
    },
    watch: {
      val() {
        this.$emit("input", this.val);
      }
    },
  }
</script>
