<template>
  <v-container fluid>
    <v-row >
      <v-col sm="12" md="6">
        <main-guest :error="showError" :value="value.MainGuest"/>
      </v-col>
      <v-col  sm="12" md="5" offset-md="1" v-if="value.AdditionalGuests.length || value.AdditionalChildren.length || value.AdditionalBabies.length">

        <additional-guest v-for="(guest,index) in value.AdditionalGuests" :key="'guest'+index"
                          :info="'Extra gast ' + (index+1)"
                          :value="guest"/>
        <additional-guest v-for="(guest,index) in value.AdditionalChildren" :key="'child'+index"
                          :info="'Extra kind ' + (index+1)"
                          :value="guest"/>
        <additional-guest v-for="(guest,index) in value.AdditionalBabies" :key="'baby'+index"
                          :info="'Extra babie ' + (index+1)"
                          :value="guest"/>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>

import MainGuest from "@/components/guests/MainGuest.vue"
import AdditionalGuest from "@/components/guests/AdditionalGuest.vue"
import {Booking} from "@/models/booking";

export default {
  data() {
    return {
      showError: false
    }
  },
  props: {
    value: [Booking],
    doValidation: Boolean
  },
  methods: {
    prev() {
      this.$emit('prev');
    },
    next() {
      if (this.validate())
        this.$emit('next');
    },
    validate() {

      this.showError = false;
      let keys = ['FirstName', 'LastName', 'Street', 'Number', 'Postal', 'City', 'Email', 'Phone', 'DateOfBirth'];

      for (let key in keys) {
        if (this.value.MainGuest[keys[key]] === "") {
          this.showError = true;
          return false;
        }
      }

      keys = ['FirstName', 'LastName', 'DateOfBirth'];

      /*let a = [...this.value.AdditionalGuests, ...this.value.AdditionalChildren, ...this.value.AdditionalBabies];

      for (const [index, item] of Object.entries(a)) {
        for (let key in keys) {
          if (item[keys[key]] === "") {
            this.showError = true;
            return false;
          }
        }
      };
      */

      this.$emit("next");

      return true;
    }
  },
  components: {
    MainGuest,
    AdditionalGuest
  }
}
</script>