export class BookingDetail {
	IdBooking: number = 0;
	Adults: number = 0;
	Children: number = 0;
	Babies: number = 0;
	Firstname: string = ""
	Lastname: string = ""
	Email: string = ""
	Info: string = ""
	PaymentID: string = ""
	Phone: string = ""

	public constructor(data?:any){
		if (!data) return;
		this.IdBooking = data.id_booking || 0;
		this.Adults = parseInt(data.adults) || 0;
		this.Children = parseInt(data.children) || 0;
		this.Babies = parseInt(data.babies) || 0;
		this.Firstname = data.firstname || "";
		this.Lastname = data.lastname || "";
		this.Email = data.email || ""
		this.Info = data.info || "";
		this.Phone = data.phone || "";
		this.PaymentID = data.payment_id || "";

	}
}