import {Axios} from "@/utils/axios";
import {Room} from "@/models/room";


export class RoomServiceClass {

	public url: string = "https://booking-api.webcode.be/api"

	async get() {
		try {
			let response = await Axios.get(this.url + "/rooms");
			if (response.status == 200) {
				return response.data.data.map((a:any) => new Room(a));
			}
		} catch (e) {

		}
	}

	async single(id:number) {
		try {
			let response = await Axios.get(this.url + "/room/"+id);
			if (response.status == 200) {
				return new Room(response.data.data);
			}
		} catch (e) {

		}
	}

}

export const RoomService = new RoomServiceClass();