<template>
  <div :class="cl" :style="{left:left+'px',width:width,right:event.Right}">
    <div class="c-event-wrapper">
        <slot name="fullname" />
    </div>
  </div>
</template>

<script>
import {CalendarState} from "@/utils/calendar-state";

export default {

  data() {
    return {
      left: 0,
      width: 0,
      calendarState: CalendarState,
    }
  },
  props: {
    event: Object,
  },
  computed: {
    cl() {
      return {'c-event': true, [this.getClass()]: true};
    }
  },
  methods: {
    getClass() {
      return "status_" + this.event.Status;
    },
    updateSize() {
      if (this.event.Right !== "auto") {
        if (this.event.ForceLeft) {
          this.left = 237;
        } else {
          this.left = 240 + (this.calendarState.state.cellWidth / 2) + (this.event.Steps * this.calendarState.state.cellWidth);
        }
        this.width = "auto";
        return;
      }

      if (this.event.ForceLeft) {
        this.left = 237;
        this.width = (this.event.Width * this.calendarState.state.cellWidth) + 10 + "px";
      } else {
        this.left = 240 + (this.calendarState.state.cellWidth / 2) + (this.event.Steps * this.calendarState.state.cellWidth);
        this.width = (this.event.Width * this.calendarState.state.cellWidth) - 2 + "px";
      }

    },
  },
  watch: {
    "calendarState.state.cellWidth"() {
      this.updateSize();
    }
  },
  mounted() {
    this.updateSize();
  }
}
</script>

<style>
   .c-event { padding: 2px; height: 100%; }
   .c-event-wrapper { font-size: 12px; height: 100%; display: flex; justify-content: center; align-items: center; overflow: hidden; }
   .c-event-wrapper:hover { background: #d2fad2; border-color: #a2caa2; transition: ease-in-out all 0.2s; cursor: pointer; }
   .c-event.status_1 > .c-event-wrapper { background: #d4eaf2; border: 1px solid #afc8d0; }
   .c-event.status_2 > .c-event-wrapper {background: #d4f2d4; border: 1px solid #c2e3c2;}
   .c-event.status_3 > .c-event-wrapper {background: #f8ce99; border: 1px solid #ddb07c; }
   .c-event.status_4 > .c-event-wrapper {background: #e7ecef; border: 1px solid #9da2a7; }
   .c-event.status_5 > .c-event-wrapper {background: #f3dedf; border: 1px solid #ec7676; }
</style>