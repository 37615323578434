<template>
  <div class="c-row">
    <div class="c-row-title">{{ room.Name }}</div>
    <calendar-event v-for="(event,index) in calendarEvents" :key="event.ID" :event="event" @click.native="open(event)">
      <div slot="fullname">
        <span class="fullname"><div>{{ getInitials(event) }}</div><div class="payed" v-if="event.PaymentID">&euro;</div></span>
      </div>
    </calendar-event>
    <calendar-cel v-for="item in 10" :key="'c-'+item" @click.native="add(item)"/>
  </div>
</template>

<style scoped>
  .fullname{display: flex; flex-direction: row;}
  .fullname .payed{
    background: #283138; font-weight: bold;
    color:#fff; border-radius: 11px; width: 22px; height: 22px; text-align: center; padding: 0px 6px; margin-left: 5px; }
  .c-row {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    display: flex;
    flex-direction: row;
    position: relative;
  }
  .c-row-title { width: 240px; border-right: 1px solid rgba(0, 0, 0, 0.12); padding: 10px 15px; z-index: 1; background: #fff; }
  >>> .c-cel:last-child { border-right: 0; }
  >>> .c-cel:hover { background: #eeeef6; }
  >>> .c-event { position: absolute; }
</style>
<script>
import CalendarCel from "@/components/calendar/CalendarCel";
import CalendarEvent from "@/components/calendar/CalendarEvent";
import {Room} from "@/models/room";
import {Booking} from "@/models/booking";
import {CalendarBooking} from "@/models/calendar-booking";

const {CalendarState} = require("@/utils/calendar-state");

export default {
  data() {
    return {
      rowTitleSize: 240,
      forceLeft: false,
      calendarState: CalendarState,
    }
  },
  components: {CalendarEvent, CalendarCel},
  props: {
    events: Array,
    from: Date,
    room: Room
  },
  computed: {
    calendarEvents() {
      return this.events.map((a) => {
        a = this.getSteps(a);
        a.Width = this.getWidth(a);
        return new CalendarBooking(a)
      });
    },
    dateRange() {
      let d = new Date(this.from.getTime());
      let r = [];
      for (let i = 1; i < 11; i++) {
        d.setDate(d.getDate() + (i === 1 ? 0 : 1));
        r.push([d.toLocaleDateString("nl-NL", {weekday: 'short', timeZone: 'UTC'}), d.getDate(), new Date(d).setHours(12,0,0)])
      }
      return r;
    }
  },
  watch: {
  },
  methods: {
    getInitials(event){
      let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');
      let initials = [...event.Name.matchAll(rgx)] || [];
      return event.Width > 1 ? event.Name : (
          (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
      ).toUpperCase();
    },
    open(event) {
      this.$emit('open', event);
    },
    add(event) {
      if (this.dateRange[event - 1][2]) {
        this.$emit('new',
            new Booking({
              from: this.dateRange[event - 1][2],
              till: this.dateRange[event][2],
              id_room: this.room.ID,
              id_accomodation: this.room.IdAccomodation,
              adults: 2,
              status: "1",
              cancellation_insurance: 0
            }));
      }
    },
    getSteps(event) {
      if (event.From < this.from && event.Till > this.calendarState.state.till) {
        event.ForceLeft = true;
        event.Steps = 10;
        event.Width = this.getWidth(event);
        event.Right = "-3px";
        return event;
      }

      if (event.From > this.from && event.Till > this.calendarState.state.till) {
        event.ForceLeft = false;
        let tt = event.From.getTime() - this.from.getTime();
        let td = tt / (1000 * 3600 * 24);
        event.Steps = parseInt(td.toFixed(0));
        event.Width = this.getWidth(event);
        event.Right = "-3px";
        return event;
      }

      if (this.from.getDate() === event.From.getDate()) {
        event.ForceLeft = false;
        event.Steps = 0;
        event.Width = this.getWidth(event);
        event.Right = "auto";
        return event;
      }
      if (this.from.getDate() === event.Till.getDate()) {
        event.ForceLeft = true;
        event.Steps = -1;
        event.Width = this.getWidth(event);
        event.Right = "auto";
        return event;
      }
      if (this.from > event.From) {
        event.ForceLeft = true;
        let tt = event.From.getTime() - this.from.getTime();
        let td = tt / (1000 * 3600 * 24);
        event.Steps = parseInt(td.toFixed(0));
        event.Width = this.getWidth(event);
        event.Right = "auto";
        return event;
      }
      if (this.from < event.From) {
        event.ForceLeft = false;
        let tt = event.From.getTime() - this.from.getTime();
        let td = tt / (1000 * 3600 * 24);
        event.Steps = parseInt(td.toFixed(0));
        event.Width = this.getWidth(event);
        event.Right = "auto";
        return event
      }
    },
    getWidth(event) {
      let tt;
      //event date is bigger than calendar
      if (event.From < this.from && event.Till > this.calendarState.state.till) {
        return 9;
      }
      if (this.from.getDate() === event.From.getDate()) {
        tt = event.Till.getTime() - this.from.getTime();
        return Math.floor(tt / (1000 * 3600 * 24));
      }
      if (this.from > event.From) {
        tt = event.Till.getTime() - this.from.getTime();
        return Math.floor(tt / (1000 * 3600 * 24)) + 0.42
      } else {
        tt = event.Till.getTime() - event.From.getTime();
      }
      return tt / (1000 * 3600 * 24);
    }
  },
  created() {
  }
}
</script>