export class CalendarBooking {
	ID: number = 0;
	From: Date = new Date();
	Till: Date = new Date();
	Name: String = "";
	Left: number = 0;
	Right: string = "auto";
	Steps: number = 0;
	ForceLeft: boolean = false;
	Width: number = 0;
	HideName: boolean = false;
	Status: number = 1;
	PaymentID: string = "";

	public constructor(data?:any){
		if (!data) return;
		this.ID = data.ID;
		this.From = data.From;
		this.Till = data.Till;
		this.Name = data.Name;
		this.Left = data.Left;
		this.Right = data.Right || "auto";
		this.Steps = data.Steps;
		this.ForceLeft = data.ForceLeft;
		this.Width = data.Width;
		this.HideName = data.HideName
		this.Status = data.Status
		this.PaymentID = data.PaymentID
	}
}