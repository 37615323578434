<template>
  <v-card outlined>
    <v-card-title class="d-flex flex-row justify-center">
      <v-icon class="mr-5" @click="reverze">
        mdi-skip-backward
      </v-icon>
      <v-icon class="mr-5" @click="previous">
        mdi-skip-previous-outline
      </v-icon>
      <div class="fulldate" @click="today">{{ fullDate }}</div>
      <v-icon class="ml-5" @click="next">
        mdi-skip-next-outline
      </v-icon>
      <v-icon class="ml-5" @click="fast">
        mdi-skip-forward
      </v-icon>
    </v-card-title>
    <v-card-text :style="{padding:'0 0 0 0'}" >
        <CalendarHeaderRow :from="from"/>
        <div :style="{height:'47px',width:'100%'}" />
        <CalendarRow @open="editBookingItem($event)" @new="newBookingItem($event)" :from="from" v-for="room in rooms" :room="room" :key="room.ID+'-'+(from.getTime() / 1000).toFixed(0)" :events="filterBookings(room.ID)"/>
    </v-card-text>
    <v-dialog
        v-model="openBooking"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
    >
      <booking-modal :booking="bookingItem" @update="update()" @close="openBooking=false" />
    </v-dialog>
  </v-card>
</template>

<script>
import CalendarRow from "@/components/calendar/CalendarRow";
import CalendarHeaderRow from "@/components/calendar/CalendarHeaderRow";
import {CalendarState} from "@/utils/calendar-state";
import {RoomService} from "@/services/room-service";
import {BookingService} from "@/services/booking-service";
import BookingModal from "@/components/booking/BookingModal";

const {CalendarBooking} = require("@/models/calendar-booking");
const {Room} = require("@/models/room");
const {Booking} = require("@/models/booking");

export default {
  name: 'Calendar',
  components: {BookingModal, CalendarHeaderRow, CalendarRow},
  data() {
    return {
      rooms: Array[Room] = [],
      from: new Date(),
      till: new Date(),
      celSize: 0,
      calendarState: CalendarState,
      bookings: Array[Booking] = [],
      openBooking: false,
      bookingItem: Booking
    };
  },
  watch: {
    from(){
      this.calendarState.state.from = this.from;
    },
    till() {
      this.calendarState.state.till = this.till;
    }

    },
  methods: {
    async fetch(){
      this.bookings = await BookingService.get(this.from.toISOString().split('T')[0],this.till.toISOString().split('T')[0]);
    },
    async update(){
      await this.fetch();
      this.openBooking = false;
    },
    async editBookingItem(item){
      this.bookingItem = await BookingService.single(item.ID);
      this.openBooking=true;
    },
    newBookingItem(event){
      this.bookingItem = event;
      this.openBooking=true;
    },
    today(){
      this.from = new Date();
      this.till = new Date();
      let t = new Date(this.from.getTime());
      t.setDate(t.getDate() - 4);
      t.setHours(1,0,0,0)
      this.from=t;
      let x= new Date(this.from.getTime());
      x.setDate(t.getDate() + 9);
      this.till=x
    },
    previous(){
      let t = new Date(this.from.getTime());
      t.setDate(t.getDate() - 6);
      this.from=t;
      let x= new Date(this.from.getTime());
      x.setDate(t.getDate() + 9);
      this.till=x
      this.fetch()
    },
    next(){
      let t = new Date(this.from.getTime());
      t.setDate(t.getDate() + 6);
      this.from=t;
      let x= new Date(this.from.getTime());
      x.setDate(t.getDate() + 9);
      this.till=x
      this.fetch();
    },
    reverze(){
      let t = new Date(this.from.getTime());
      t.setMonth(t.getMonth() - 1);
      this.from=t;
      let x= new Date(this.from.getTime());
      x.setDate(t.getDate() + 9);
      this.till=x
      this.fetch()
    },
    fast(){
      let t = new Date(this.from.getTime());
      t.setMonth(t.getMonth() + 1);
      this.from=t;
      let x= new Date(this.from.getTime());
      x.setDate(t.getDate() + 9);
      this.till=x
      this.fetch();
    },
    resize() {
        let el = document.getElementsByClassName('c-cel');
        if(el[0]){
            setTimeout(() => this.calendarState.state.cellWidth = (el[0].getBoundingClientRect().width) , 50);
        }

    },
    filterBookings(room){
      if(!this.bookings)
        return [];

      let b = this.bookings.filter((b)=>b.IdRoom === room)
      return b;
    }
  },
  computed: {
    fullDate(){
      let t = new Date(this.from.getTime());
      t.setDate(t.getDate() + 4);
      return t.getDate() + " " + t.toLocaleString("nl-NL", { month: 'short',timeZone: 'UTC' }) + " " + t.getFullYear();
    }
  },
  async mounted() {
    this.$nextTick(() => {
      this.resize();
    })
    await this.fetch();
    this.rooms = await RoomService.get();
  },
  async created() {
    this.today();
    window.addEventListener("resize", this.resize);
  },
  destroyed() {
    window.removeEventListener("resize", this.myEventHandler);
  }
}
</script>
<style>
 *{
   -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
   -moz-box-sizing: border-box;    /* Firefox, other Gecko */
   box-sizing: border-box;
 }
</style>
<style scoped>

.fulldate{width: 120px; text-align: center; cursor: pointer; }
.fulldate:hover{ color:#673ab7; }
.v-card{
  height: calc(100vh - 90px);
  max-height: 100%;
  display: flex !important;
  flex-direction: column;
}

.v-card__text{
  padding: 0 0 0 0 !important;
  flex-grow: 1;
  overflow: auto;
  overflow-x:hidden;
}

</style>